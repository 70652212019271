import { SDX_IFRAME_HEIGHT_PADDING } from "./see-how-data-is-managed-view-constants";

/**
 * The iframe source endpoint for this view uses the `document.body.scrollHeight` property when sending IDUX the iframe height
 * after load. However, it does not take into account the incorrect `scrollHeight` property bug on IE / MS Edge outlined below -
 * https://learn.microsoft.com.office.wyliemilkrp.wyliemilk.myshn.net/en-us/troubleshoot/developer/browsers/development-website/scrollheight-return-incorrect-values
 * As a result, the iframe does not display all the content served by the endpoint (scrollbar / overflow is hidden so user is
 * unable to scroll down to view the missing content).
 *
 * In order to address this problem, a fixed padding is added to the height as suggested by the `Workaround` section of the bug
 * documentation linked above. This function implements this workaround.
 *
 * Note that a longer term fix is to use `document.scrollingElement.scrollHeight` instead of `document.body.scrollHeight` in
 * the client-side code of the iframe endpoint. However, this is out of scope of IDUX so we settle for this hotfix for now.
 *
 * @param iFrameHeight The iframe height as supplied by the post message from the client-side code for the iframe source endpoint
 * @returns The actual iframe height adjusted for incorrect scrollHeight property bug on IE / MS Edge
 */
export const computeAdjustedIFrameHeight = (iFrameHeight: string): string => {
  const match = iFrameHeight.match(/^(\d+)(px)?$/);

  // If no matches are found, fall back to the input height
  if (match === null) {
    return iFrameHeight;
  }

  // Parse the group matching the numerical portion of the height string into an integer
  const iFrameHeightInt = parseInt(match[1], 10);

  // Calculate and return the adjusted height
  const adjustedIFrameHeight = iFrameHeightInt + SDX_IFRAME_HEIGHT_PADDING;
  return `${adjustedIFrameHeight}px`;
};
